import { useRef, useState } from "react"
import { Button, Card, CardMedia, TextField, Typography } from '@mui/material'
import placeholder from "./placeholder.webp"
import css from "./BarbeibotImage.module.css"
import ModalImagenBarbeibot from "../../modals/ModalImagenBarbeibot/ModalImagenBarbeibot"

const BarbeibotImage = ({ onSubmit }) => {


  const [question, setQuestion] = useState('')
  const [imgPrev, setImgPrev] = useState(null)
  const [showImage, setShowImage] = useState(false)
  const [isProcessing, setProcessing] = useState(false)

  const fileInputRef = useRef(null)

  const handleFileChange = (event) => {
    const hasFile = event.target && event.target.files && event.target.files.length > 0
    if (!hasFile) return removeFile()
    const selectedFile = event.target.files[0]
    const url = URL.createObjectURL(selectedFile)
    setImgPrev(url)
  }

  const removeFile = () => {
    setImgPrev(null)
    setShowImage(false)
    if (fileInputRef.current) fileInputRef.current.value = ""
  }

  const getFileFromBlobUrl = async (blobUrl)=> {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], "imagen.png", { type: blob.type });
  }

  const handleImageClick = ()=> {
    if (!imgPrev) {
      fileInputRef.current.click()
      return
    }
    setShowImage(true)
  }

  const handleSubmit = async () => {
    if (question.trim() === '') return
    if (!imgPrev) return
    setProcessing(true)
    const file = await getFileFromBlobUrl(imgPrev)
    onSubmit({ question, file })
    setQuestion('')
    setImgPrev(null)
    setShowImage(false)
    setProcessing(false)
  }

  return (
    <div className={css.main}>
      <Typography variant="h6" gutterBottom>
        Analiza cualquier imagen con el Barbeibot
      </Typography>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        ref={fileInputRef}
        hidden
      />
      <div className={css.fields}>
        <div className={css.imageField}>
          <Card 
            className={css.card}
            onClick={handleImageClick}
          >
            <CardMedia
              className={css.image}
              data-content={imgPrev ? 'image' : 'placeholder'}
              component="img"
              image={imgPrev || placeholder}
              alt="Imagen de analisis"
            />
          </Card>
        </div>
        <div className={css.textField}>
          <TextField
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className={css.textarea}
            label=""
            placeholder="Escribe tu pregunta"
            multiline
            minRows={5}
            maxRows={8}
          />
        </div>
      </div>
      <Button 
        className={css.button} 
        disabled={isProcessing || !question || !imgPrev}
        onClick={handleSubmit} 
        variant="contained" 
        color="primary"
      >
        Preguntar
      </Button>
      <ModalImagenBarbeibot
        open={showImage}
        onClose={()=> setShowImage(false)}
        onDelete={removeFile}
        url={imgPrev}
      />
    </div>
  )
}

export default BarbeibotImage

import { POST, PUT } from '../request'

export const preguntaSimple = ({ pregunta }) => POST('/api/profes/interno/ia/consulta-barbeibot/simple', {}, { pregunta })

export const preguntaImagen = ({ pregunta, image }) => {
    const formData = new FormData()
    formData.append("pregunta", pregunta)
    if (image) formData.append("image", image)
    return PUT('/api/profes/interno/ia/consulta-barbeibot/image', {}, formData, true);
}

